body {
  margin: 0;
  padding: 0;
}

input,
select,
textarea {
  font-size: 16px;
}
